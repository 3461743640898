import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavbarV2 from './NavbarV2';
import BASE_URL from './ipconfig'; // Import the BASE_URL
import { styled } from '@mui/material/styles';
const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

export default function Signup() {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSignUp = async (event) => {
    event.preventDefault();
    const newUser = { name, lastName, email };

    try {
      await axios.post(`${BASE_URL}/signup`, newUser);
      setName('');
      setLastName('');
      setEmail('');
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <NavbarV2 />
      <Box sx={{ flexGrow: 1, backgroundColor: '#f0f8ff', display: 'flex', flexDirection: 'column' }}>
        <Container sx={{ backgroundColor: '#ffffff', flexGrow: 1, mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  SIGN UP TODAY
                </Typography>
                <Divider />
                <Box component="form" onSubmit={handleSignUp} sx={{ mt: 2 }}>
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    required
                    fullWidth
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                  />
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Sign Up
                  </Button>
                </Box>
              </SectionBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: '#2196f3', color: '#fff', p: 2, textAlign: 'center' }}>
        <Typography variant="body1">Mete Build V1</Typography>
      </Box>
    </Box>
  );
}
