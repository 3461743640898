import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavbarV2 from './NavbarV2';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import BASE_URL from './ipconfig'; // Import the BASE_URL
import image1 from './20210809_193138.jpg';
import image2 from './20240706_205600.jpg';
import image3 from './20220910_192047.jpg';
import image4 from './20220629_203305.jpg';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  const StyledBox = styled(Box)(({ theme }) => ({
    border: '1px solid #ddd',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0, 0, 255, 0.2)',
    overflow: 'hidden',
    width: '200px',
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
  }));
  
  const ImageBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '90%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }));
  
  const TitleBox = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'left',
    height: '10%',
  }));
  
  const HeroBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '400px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    transition: 'background-image 1s ease-in-out', // Smooth transition
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing(1),
    },
  }));
  
  const SectionBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(4),
  }));
  
  const AnnouncementAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.info.light,
  }));
  
  const EventAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.success.light,
  }));
  
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  const urlify = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? <Link href={part} target="_blank" rel="noopener noreferrer" key={index}>{part}</Link> : part
    );
  };
export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [dialogues, setDialogues] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [expanded, setExpanded] = useState({});
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const pictureReel = [image1, image2, image3, image4];

  useEffect(() => {
    fetchAnnouncements();
    fetchEvents();
    fetchPictures();
    fetchVideos();
    fetchDialogues();

    const pictureInterval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % pictureReel.length);
    }, 10000);

    return () => clearInterval(pictureInterval);
  }, []);

  const fetchAnnouncements = async () => {
    const response = await axios.get(`${BASE_URL}/announcements`);
    setAnnouncements(response.data);
  };

  const fetchEvents = async () => {
    const response = await axios.get(`${BASE_URL}/events`);
    setEvents(response.data);
  };

  const fetchPictures = async () => {
    const response = await axios.get(`${BASE_URL}/pictures`);
    setPictures(response.data);
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  const fetchDialogues = async () => {
    const response = await axios.get(`${BASE_URL}/dialogues`);
    setDialogues(response.data);
  };

  const handleClickOpen = (picture) => {
    setCurrentImage(picture);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };
  return (
    <Box sx={{ width: '100%', minHeight: '10vh' }}> 
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box></Box><Typography variant="h5">UPCOMING ASSEMENTS</Typography>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="READINGS" {...a11yProps(0)} />
          <Tab label="Assignments" {...a11yProps(1)} />
          <Tab label="Project" {...a11yProps(2)} />
          <Tab label="Quiz  " {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
   

      <Grid container spacing={4} justifyContent="center"> {/* Increased spacing between cards */}
    {dialogues.map((dialogue, index) => (
      <Grid item key={index} xs={12} sm={6} md={4}>
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          boxShadow: 3,  // Adds slight shadow for better distinction
          borderRadius: '10px'  // Rounded corners
        }}>
          <CardMedia
            component="img"
            image={`${BASE_URL}/${dialogue.picture}`}
            alt={`dialogue-pic-${index}`}
            sx={{
              width: '100%',
              height: '200px',  // Fixed height for all images
              objectFit: 'cover',  // Ensures consistent image display
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px'
            }}
          />
          <CardContent sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              {dialogue.title}
            </Typography>
            <Divider sx={{ width: '100%', mb: 1 }} />
            <Box sx={{
              maxHeight: '120px',  // Set height for text box
              overflowY: 'auto',  // Adds scroll bar when content exceeds height
              overflowX: 'hidden',  // Prevent horizontal scrolling
              whiteSpace: 'normal',  // Ensure the text wraps
              wordWrap: 'break-word',  // Break long words
              paddingRight: 1
            }}>
              <Typography variant="body2" color="text.secondary">
                {dialogue.text}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))}
</Grid>



      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      THERE ARE NO Assignments currently
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      THERE ARE NO Projects currently
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      THERE ARE NO Quizzes currently
      </CustomTabPanel>
    </Box>
  );
}