import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Divider } from '@mui/material';
import axios from 'axios';
import BASE_URL from './ipconfig'; // Import the BASE_URL
const AnnouncementsForm = ({ fetchAnnouncements }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { title, description, date };

    try {
      await axios.post(`${BASE_URL}/announcements`, data);


      fetchAnnouncements();
      setTitle('');
      setDescription('');
      setDate('');
    } catch (error) {
      console.error('Error submitting announcement:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
        Create Announcement
      </Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default AnnouncementsForm;
