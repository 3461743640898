import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import NavbarV2 from './NavbarV2';
import Home from "./Home";
import HomeAdmin from "./HomeAdmin";
import Pg3 from './Pages/Pg3';
import Pg1 from './Pages/Pg1';
import Pg2 from './Pages/Pg2';
import Login from './Login';
import Gallery from "./Gallery";
import News from "./News";
import Signup from "./Signup";
import Assignments from "./Assignments";
import Courseinfo from "./Courseinfo";
import Projects from "./Projects";
import Syllabus from "./Syllabus"
function App() {
  const userType = localStorage.getItem('user_type');

  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/HomeAdmin" element={userType === 'admin' ? <HomeAdmin /> : <Navigate to="/Login" />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Pg3" element={<Pg3 />} />
          <Route path="/Pg1" element={<Pg1 />} />
          <Route path="/Pg2" element={<Pg2 />} />
          <Route path="/News" element={<News />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/Assignments" element={<Assignments />} />
          <Route path="/Course-info" element={<Courseinfo />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Syllabus" element={<Syllabus />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
