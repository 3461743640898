import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BASE_URL from './ipconfig'; // Import the BASE_URL
import NavbarV2 from './NavbarV2';
import AnnouncementsForm from './AnnouncementsForm';
import EventsForm from './EventsForm';
import CardActions from '@mui/material/CardActions';
import ListItem from '@mui/material/ListItem';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '80%', // Make the dialog occupy 80% of the page width
    maxWidth: '80%', // Ensure the dialog doesn't exceed 80% width
    height: '80vh', // Make the dialog occupy 80% of the page height
    maxHeight: '80vh', // Ensure the dialog doesn't exceed 80% height
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HomeAdmin() {
  const [pictures, setPictures] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [videoFormOpen, setVideoFormOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [videos, setVideos] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editDate, setEditDate] = useState('');

  // State to manage dialogues
  const [dialogues, setDialogues] = useState([]);
  const [dialogueFormOpen, setDialogueFormOpen] = useState(false);
  const [dialogueTitle, setDialogueTitle] = useState('');
  const [dialogueText, setDialogueText] = useState('');
  const [dialogueFile, setDialogueFile] = useState(null);
  const [expanded, setExpanded] = useState({});

  // State to manage users
  const [users, setUsers] = useState([]);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [currentEditUser, setCurrentEditUser] = useState(null);
  const [editName, setEditName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editNewsOpen, setEditNewsOpen] = useState(false);
  const [currentEditNews, setCurrentEditNews] = useState(null);
  const [editNewsTitle, setEditNewsTitle] = useState('');
  const [editNewsText, setEditNewsText] = useState('');
  const [editNewsFile, setEditNewsFile] = useState(null);
  
  useEffect(() => {
    fetchAnnouncements();
    fetchEvents();
    fetchPictures();
    fetchVideos();
    fetchDialogues(); // Fetch dialogues
    fetchUsers(); // Fetch users
  }, []);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleVideoFormOpen = () => {
    setVideoFormOpen(true);
  };

  const handleDialogueFormOpen = () => {
    setDialogueFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  const handleVideoFormClose = () => {
    setVideoFormOpen(false);
  };

  const handleDialogueFormClose = () => {
    setDialogueFormOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const fetchPictures = async () => {
    const response = await axios.get(`${BASE_URL}/pictures`);
    setPictures(response.data);
  };

  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/announcements`);
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/events`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/videos`);
      const videosWithThumbnails = response.data.map(video => {
        let videoId = video.link.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        return { ...video, thumbnailUrl };
      });
      setVideos(videosWithThumbnails);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const fetchDialogues = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dialogues`);
      setDialogues(response.data);
    } catch (error) {
      console.error('Error fetching dialogues:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/signup`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handlePictureUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }
    const formData = new FormData();
    formData.append('picture', selectedFile);
    formData.append('title', title);
  
    try {
      await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchPictures();
      setSelectedFile(null);
      setTitle('');
      setFormOpen(false);
    } catch (error) {
      console.error('Error uploading picture:', error);
      alert('Failed to upload picture.');
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file); // This will log the file details to the console
    setSelectedFile(file);
  };
  

  const handleVideoUpload = async (event) => {
    event.preventDefault();
    const videoData = { videoUrl: videoUrl, description: videoDescription };

    try {
      await axios.post(`${BASE_URL}/videos`, videoData);
      fetchVideos();
      setVideoUrl('');
      setVideoDescription('');
      setVideoFormOpen(false);
    } catch (error) {
      console.error('Error uploading video:', error);
      alert('Failed to upload video.');
    }
  };

  const handleDialogueSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('picture', dialogueFile);
    formData.append('title', dialogueTitle);
    formData.append('text', dialogueText);

    try {
      await axios.post(`${BASE_URL}/dialogues`, formData);
      fetchDialogues();
      setDialogueTitle('');
      setDialogueText('');
      setDialogueFile(null);
      setDialogueFormOpen(false);
    } catch (error) {
      console.error('Error uploading dialogue:', error);
      alert('Failed to upload dialogue.');
    }
  };

  const handleClickOpen = (picture) => {
    setCurrentImage(picture);
    setOpen(true);
  };

  const handleEditOpen = (item) => {
    setCurrentEditItem(item);
    setEditTitle(item.title);
    setEditDescription(item.description);
    setEditDate(new Date(item.date).toISOString().substring(0, 10));
    setEditOpen(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const updatedItem = {
      ...currentEditItem,
      title: editTitle,
      description: editDescription,
      date: editDate,
    };

    try {
      if (currentEditItem.type === 'announcement') {
        await axios.put(`${BASE_URL}/announcements/${currentEditItem.id}`, updatedItem);
        fetchAnnouncements();
      } else if (currentEditItem.type === 'event') {
        await axios.put(`${BASE_URL}/events/${currentEditItem.id}`, updatedItem);
        fetchEvents();
      }
      handleEditClose();
    } catch (error) {
      console.error('Error editing item:', error);
      alert('Failed to edit item.');
    }
  };
  
  const handleEditNewsSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', editNewsTitle);
    formData.append('text', editNewsText);
    if (editNewsFile) {
      formData.append('picture', editNewsFile);
    }
  
    try {
      await axios.put(`${BASE_URL}/dialogues/${currentEditNews.id}`, formData);
      fetchDialogues();
      handleEditNewsClose();
    } catch (error) {
      console.error('Error editing news:', error);
      alert('Failed to edit news.');
    }
  };

  const handleEditNewsOpen = (newsItem) => {
    setCurrentEditNews(newsItem);
    setEditNewsTitle(newsItem.title);
    setEditNewsText(newsItem.text);
    setEditNewsFile(null); // Reset the file input
    setEditNewsOpen(true);
  };
  
  const handleEditNewsClose = () => {
    setEditNewsOpen(false);
  };

  const handleDelete = async (item) => {
    try {
      if (item.type === 'announcement') {
        await axios.delete(`${BASE_URL}/announcements/${item.id}`);
        fetchAnnouncements();
      } else if (item.type === 'event') {
        await axios.delete(`${BASE_URL}/events/${item.id}`);
        fetchEvents();
      } else if (item.type === 'picture') {
        await axios.delete(`${BASE_URL}/pictures/${item.id}`);
        fetchPictures();
      } else if (item.type === 'video') {
        await axios.delete(`${BASE_URL}/videos/${item.id}`);
        fetchVideos();
      } else if (item.type === 'dialogue') {
        await axios.delete(`${BASE_URL}/dialogues/${item.id}`);
        fetchDialogues();
      } else if (item.type === 'user') {
        await axios.delete(`${BASE_URL}/signup/${item.id}`);
        fetchUsers();
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Failed to delete item.');
    }
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const handleEditUserOpen = (user) => {
    setCurrentEditUser(user);
    setEditName(user.name);
    setEditLastName(user.lastName);
    setEditEmail(user.email);
    setEditUserOpen(true);
  };

  const handleEditUserClose = () => {
    setEditUserOpen(false);
  };

  const handleEditUserSubmit = async (event) => {
    event.preventDefault();
    const updatedUser = {
      ...currentEditUser,
      name: editName,
      lastName: editLastName,
      email: editEmail,
    };

    try {
      await axios.put(`${BASE_URL}/signup/${currentEditUser.id}`, updatedUser);
      fetchUsers();
      handleEditUserClose();
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user.');
    }
  };

  const downloadCSV = () => {
    const csvRows = [
      ['Name', 'Last Name', 'Email'],
      ...users.map(user => [user.name, user.lastName, user.email])
    ];

    const csvString = csvRows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'users.csv');
    a.click();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <NavbarV2 />
      <Box sx={{ flexGrow: 1, backgroundColor: '#f0f8ff', display: 'flex', flexDirection: 'column', paddingTop: '80px' }}>
        <Container sx={{ backgroundColor: '#ffffff', flexGrow: 1, mt: 4 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <SectionBox>
                <Button variant="contained" onClick={handleFormOpen} sx={{ mt: 2 }}>
                  Upload pictures here
                </Button>
                <BootstrapDialog
                  onClose={handleFormClose}
                  aria-labelledby="form-dialog-title"
                  open={formOpen}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="form-dialog-title">
                    Upload Picture
                    <IconButton
                      aria-label="close"
                      onClick={handleFormClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '90%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handlePictureUpload}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" component="label">
                            Choose File
                            <input
                              accept="image/*"
                              type="file"
                              hidden
                              onChange={handleFileChange}
                            />
                          </Button>
                        </Grid>
                        {selectedFile && (
                          <Grid item>
                            <Typography variant="body2">
                              Selected file: {selectedFile.name}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleFormClose}>Cancel</Button>
                    <Button type="submit" variant="contained" onClick={handlePictureUpload}>Submit</Button>
                  </DialogActions>
                </BootstrapDialog>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  Home pictures
                </Typography>
                <Divider />
                <Grid container spacing={2} justifyContent="center">
                  {pictures.map((picture, index) => (
                    <Grid item key={index}>
                      <Box
                        sx={{
                          border: '1px solid #ddd',
                          textAlign: 'center',
                          cursor: 'pointer',
                          borderRadius: '8px',
                          boxShadow: '0 4px 8px rgba(0, 0, 255, 0.2)',
                          overflow: 'hidden',
                          width: '200px',
                          height: '250px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          position: 'relative',
                        }}
                        onClick={() => handleClickOpen(picture)}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            padding: 1,
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            textAlign: 'left',
                            height: '48px',
                          }}
                        >
                          <Typography variant="subtitle1">
                            {picture.title}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%', height: 'calc(100% - 48px)' }}>
                          <img
                            src={`${BASE_URL}/${picture.path}`}
                            alt={`uploaded-pic-${index}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                        </Box>
                      </Box>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleDelete({ ...picture, type: 'picture' })}
                        sx={{ width: '100%', mt: 1 }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Button variant="contained" onClick={handleVideoFormOpen} sx={{ mt: 2 }}>
                  Upload Video
                </Button>
                <BootstrapDialog
                  onClose={handleVideoFormClose}
                  aria-labelledby="video-form-dialog-title"
                  open={videoFormOpen}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="video-form-dialog-title">
                    Upload Video
                    <IconButton
                      aria-label="close"
                      onClick={handleVideoFormClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '90%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleVideoUpload}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <TextField
                            id="video-url"
                            label="Video URL"
                            variant="outlined"
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="video-description"
                            label="Description"
                            variant="outlined"
                            value={videoDescription}
                            onChange={(e) => setVideoDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleVideoFormClose}>Cancel</Button>
                    <Button type="submit" variant="contained" onClick={handleVideoUpload}>Submit</Button>
                  </DialogActions>
                </BootstrapDialog>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  Uploaded Videos
                </Typography>
                <Divider />
                <Grid container spacing={2} justifyContent="center">
                  {videos.map((video, index) => (
                    <Grid item key={index}>
                      <Box
                        sx={{
                          border: '1px solid #ddd',
                          textAlign: 'center',
                          cursor: 'pointer',
                          borderRadius: '8px',
                          boxShadow: '0 4px 8px rgba(0, 0, 255, 0.2)',
                          overflow: 'hidden',
                          width: '200px',
                          height: '250px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            padding: 1,
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            textAlign: 'left',
                            height: '48px',
                          }}
                        >
                          <Typography variant="subtitle1">
                            {video.description}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%', height: 'calc(100% - 48px)' }}>
                          <img
                            src={video.thumbnailUrl}
                            alt={`video-thumbnail-${index}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                        </Box>
                      </Box>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleDelete({ ...video, type: 'video' })}
                        sx={{ width: '100%', mt: 1 }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Button variant="contained" onClick={handleDialogueFormOpen} sx={{ mt: 2 }}>
                  Add Reading
                </Button>
                <BootstrapDialog
                  onClose={handleDialogueFormClose}
                  aria-labelledby="dialogue-form-dialog-title"
                  open={dialogueFormOpen}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="dialogue-form-dialog-title">
                    Add Reading
                    <IconButton
                      aria-label="close"
                      onClick={handleDialogueFormClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '90%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleDialogueSubmit}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            value={dialogueTitle}
                            onChange={(e) => setDialogueTitle(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Body Content"
                            variant="outlined"
                            value={dialogueText}
                            onChange={(e) => setDialogueText(e.target.value)}
                            fullWidth
                            multiline
                            rows={10}
                          />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" component="label">
                            Choose Picture
                            <input
                              accept="image/*"
                              type="file"
                              hidden
                              onChange={(e) => setDialogueFile(e.target.files[0])}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogueFormClose}>Cancel</Button>
                    <Button type="submit" variant="contained" onClick={handleDialogueSubmit}>Submit</Button>
                  </DialogActions>
                </BootstrapDialog>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  READINGS
                </Typography>
                <Divider />
                <Grid container spacing={2} justifyContent="center">
                  {dialogues.map((dialogue, index) => (
                    <Grid item key={index} xs={12}>
                      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                        {dialogue.picture && (
                          <CardMedia
                            component="img"
                            image={`${BASE_URL}/${dialogue.picture}`}
                            alt={`dialogue-pic-${index}`}
                            sx={{
                              width: '99%', // Occupies 99% of the grid width
                              maxHeight: '300px',
                              objectFit: 'contain',
                              margin: '0 auto', // Center the image
                            }}
                          />
                        )}
                        <CardContent>
                          <Typography variant="h5">
                            {dialogue.title}
                          </Typography>
                          <Divider sx={{ width: '100%', mt: 1, mb: 1 }} />
                          <Typography variant="body2" color="text.secondary">
                            {dialogue.text.length > 150 ? `${dialogue.text.substring(0, 150)}...` : dialogue.text}
                          </Typography>
                        </CardContent>
                        {dialogue.text.length > 150 && (
                          <CardActions disableSpacing>
                            <ExpandMore
                              expand={expanded[index]}
                              onClick={() => handleExpandClick(index)}
                              aria-expanded={expanded[index]}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </CardActions>
                        )}
                        <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Typography paragraph>
                              {dialogue.text}
                            </Typography>
                          </CardContent>
                        </Collapse>
                        <Button variant="outlined" size="small" onClick={() => handleEditNewsOpen(dialogue)}>Edit</Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => handleDelete({ ...dialogue, type: 'dialogue' })}
                          sx={{ mt: 1 }}
                        >
                          Delete
                        </Button>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <BootstrapDialog
                  onClose={handleEditNewsClose}
                  aria-labelledby="edit-news-dialog-title"
                  open={editNewsOpen}
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="edit-news-dialog-title">
                    Edit Reading
                    <IconButton
                      aria-label="close"
                      onClick={handleEditNewsClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleEditNewsSubmit}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <TextField
                            id="edit-READ-title"
                            label="Title"
                            variant="outlined"
                            value={editNewsTitle}
                            onChange={(e) => setEditNewsTitle(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="edit-news-text"
                            label="Body Content"
                            variant="outlined"
                            value={editNewsText}
                            onChange={(e) => setEditNewsText(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" component="label">
                            Choose Picture
                            <input
                              accept="image/*"
                              type="file"
                              hidden
                              onChange={(e) => setEditNewsFile(e.target.files[0])}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button onClick={handleEditNewsClose}>Cancel</Button>
                        <Button type="submit" variant="contained">Save</Button>
                      </DialogActions>
                    </Box>
                  </DialogContent>
                </BootstrapDialog>
              </SectionBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionBox>
                <AnnouncementsForm fetchAnnouncements={fetchAnnouncements} />
              </SectionBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionBox>
                <EventsForm fetchEvents={fetchEvents} />
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  Announcements
                </Typography>
                <Divider />
                <List>
                  {announcements.map((announcement, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{announcement.title}: {announcement.description} ({new Date(announcement.date).toLocaleDateString()})</Typography>
                      <Box>
                        <Button variant="outlined" size="small" onClick={() => handleEditOpen({ ...announcement, type: 'announcement' })}>Edit</Button>
                        <Button variant="outlined" size="small" color="error" onClick={() => handleDelete({ ...announcement, type: 'announcement' })}>Delete</Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  Events
                </Typography>
                <Divider />
                <List>
                  {events.map((event, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{event.title}: {event.description} ({new Date(event.date).toLocaleDateString()})</Typography>
                      <Box>
                        <Button variant="outlined" size="small" onClick={() => handleEditOpen({ ...event, type: 'event' })}>Edit</Button>
                        <Button variant="outlined" size="small" color="error" onClick={() => handleDelete({ ...event, type: 'event' })}>Delete</Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </SectionBox>
            </Grid>
            <Grid item xs={12}>
              <SectionBox>
                <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  Signup Users
                </Typography>
                <Button variant="contained" onClick={downloadCSV} sx={{ mb: 2 }}>
                  Download CSV
                </Button>
                <Divider />
                <List>
                  {users.map((user, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1">
                        {user.name} {user.lastName}: {user.email}
                      </Typography>
                      <Box>
                        <Button variant="outlined" size="small" onClick={() => handleEditUserOpen(user)}>Edit</Button>
                        <Button variant="outlined" size="small" color="error" onClick={() => handleDelete({ ...user, type: 'user' })}>Delete</Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </SectionBox>

              {/* Edit User Dialog */}
              <BootstrapDialog
                onClose={handleEditUserClose}
                aria-labelledby="edit-user-dialog-title"
                open={editUserOpen}
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="edit-user-dialog-title">
                  Edit User
                  <IconButton
                    aria-label="close"
                    onClick={handleEditUserClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '40ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleEditUserSubmit}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          id="edit-name"
                          label="Name"
                          variant="outlined"
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="edit-lastName"
                          label="Last Name"
                          variant="outlined"
                          value={editLastName}
                          onChange={(e) => setEditLastName(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="edit-email"
                          label="Email"
                          variant="outlined"
                          value={editEmail}
                          onChange={(e) => setEditEmail(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button onClick={handleEditUserClose}>Cancel</Button>
                      <Button type="submit" variant="contained">Save</Button>
                    </DialogActions>
                  </Box>
                </DialogContent>
              </BootstrapDialog>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: '#2196f3', color: '#fff', p: 2, textAlign: 'center' }}>
        <Typography variant="body1">Mete Build V1</Typography>
      </Box>

      {/* Image Dialog */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {currentImage.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <img
            src={`${BASE_URL}/${currentImage.path}`}
            alt={currentImage.title}
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* Edit Dialog */}
      <BootstrapDialog
        onClose={handleEditClose}
        aria-labelledby="edit-dialog-title"
        open={editOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="edit-dialog-title">
          Edit {currentEditItem?.type === 'announcement' ? 'Announcement' : 'Event'}
          <IconButton
            aria-label="close"
            onClick={handleEditClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleEditSubmit}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  id="edit-title"
                  label="Title"
                  variant="outlined"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  id="edit-description"
                  label="Description"
                  variant="outlined"
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={12}  
                  sx={{ fontSize: '1rem', padding: '10px' }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="edit-date"
                  label="Date"
                  type="date"
                  variant="outlined"
                  value={editDate}
                  onChange={(e) => setEditDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleEditClose}>Cancel</Button>
              <Button type="submit" variant="contained">Save</Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/* Edit News Dialog */}
      <BootstrapDialog
        onClose={handleEditNewsClose}
        aria-labelledby="edit-news-dialog-title"
        open={editNewsOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="edit-news-dialog-title">
          Edit Reading
          <IconButton
            aria-label="close"
            onClick={handleEditNewsClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleEditNewsSubmit}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  id="edit-news-title"
                  label="Title"
                  variant="outlined"
                  value={editNewsTitle}
                  onChange={(e) => setEditNewsTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="edit-news-text"
                  label="Body Content"
                  variant="outlined"
                  value={editNewsText}
                  onChange={(e) => setEditNewsText(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" component="label">
                  Choose Picture
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    onChange={(e) => setEditNewsFile(e.target.files[0])}
                  />
                </Button>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleEditNewsClose}>Cancel</Button>
              <Button type="submit" variant="contained">Save</Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
