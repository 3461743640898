import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavbarV2 from './NavbarV2';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import BASE_URL from './ipconfig'; // Import the BASE_URL
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import RedditIcon from '@mui/icons-material/Reddit';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SiTiktok } from 'react-icons/si';
// Importing images
import image1 from './20240502_182733.jpg';
import image2 from './cap_GX011155_00_00_06_01awb.jpg';
import image3 from './20220910_192047.jpg';
import image4 from './20240101_121026.jpg';
import { Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper }from '@mui/material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: '1px solid #ddd',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 8px rgba(0, 0, 255, 0.2)',
  overflow: 'hidden',
  width: '200px',
  height: '250px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  position: 'relative',
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '90%',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Change this to cover to fill the space
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  textAlign: 'left',
  height: '10%',
}));

const HeroBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end', // Align to the bottom
  alignItems: 'flex-start', // Align to the left
  textAlign: 'left', // Align text to the left
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  transition: 'background-image 1s ease-in-out', // Smooth transition
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: theme.spacing(1),
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const AnnouncementAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
}));

const EventAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const urlify = (text) => {
  // Enhanced URL pattern to ensure better matching and avoid duplication
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text.split(urlPattern).map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <Link href={part} target="_blank" rel="noopener noreferrer" key={index}>
          {part}
        </Link>
      );
    }
    return part;
  });
};

export default function Courseinfo() {
  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [dialogues, setDialogues] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [expanded, setExpanded] = useState({});
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
  const [imageDimensions, setImageDimensions] = useState({ width: '90vw', height: '90vh' });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const pictureReel = [image1, image2, image3, image4];

  useEffect(() => {
    fetchAnnouncements();
    fetchEvents();
    fetchPictures();
    fetchVideos();
    fetchDialogues();

    const pictureInterval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % pictureReel.length);
    }, 10000);

    return () => clearInterval(pictureInterval);
  }, []);

  const fetchAnnouncements = async () => {
    const response = await axios.get(`${BASE_URL}/announcements`);
    setAnnouncements(response.data);
  };

  const fetchEvents = async () => {
    const response = await axios.get(`${BASE_URL}/events`);
    setEvents(response.data);
  };

  const fetchPictures = async () => {
    const response = await axios.get(`${BASE_URL}/pictures`);
    setPictures(response.data);
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  const fetchDialogues = async () => {
    const response = await axios.get(`${BASE_URL}/dialogues`);
    setDialogues(response.data);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  }));
  const handleClickOpen = (picture) => {
    const img = new Image();
    img.src = `${BASE_URL}/${picture.path}`;
    img.onload = () => {
      setCurrentImage(picture);
      setOpen(true);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
      <CssBaseline />
      <NavbarV2 />
      <Box sx={{ flexGrow: 1, backgroundColor: '#f0f8ff', display: 'flex', flexDirection: 'column' }}>

        <Container sx={{ backgroundColor: '#ffffff', flexGrow: 1, mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ height: '100%' }}>
               
            
                <Grid item xs={12} md={12}>
                  <SectionBox>
                    <Typography variant="h3" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                      COURSE INFO
                    </Typography>
            
                   
                   
                  </SectionBox>
                </Grid>

                <Grid item xs={12}>
            <SectionBox>
              <Typography variant="h4" sx={{ mb: 2}}>
                Tech for Everyone and Earth
              </Typography>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Become a superhero by building superintelligence for people of all abilities to be kind to each other and the earth. 
                <br />Super-human-machine interface = wearable A.I. for superhuman intelligence
              </Typography>
              <Divider />
              <Typography variant="h6" sx={{ mt: 2 }}>
                A Superhumachine is based on technology that connects us to our environment (surroundings) and invironment (our own body), forming liminal space between us and our surroundings. Examples include shoes, clothes, eyeglasses, and vehicles, especially personal conveyances like the mobility aid that helps a person with a disability find their way in life. These "vironments" define the boundary between invironment=us, and environment=surroundings. We will study and build devices like the smart self-driving walker rollator and the FreehicleTM (a freedom mobility vehicle) that operates indoors, outdoors, and on various paths.
              </Typography>
            </SectionBox>
          </Grid>
          <Grid item xs={12}>
            <SectionBox>
              <Typography variant="h4" sx={{ mb: 1}}>
                Location & Hours
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
               Bahen Centre for Information Technology Room  <Link href="https://www.google.com/maps/dir//40+St+George+St,+Toronto,+ON+M5S+2E4/@43.6597749,-79.4796987,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882b34c75165c957:0x6459384147b4b67b!2m2!1d-79.397298!2d43.6598045?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D">3165</Link>

       
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
       

                Thursdays 6pm to 9pm
              </Typography>
         
            </SectionBox>
          </Grid>

          {/* Instructor Section */}
          <Grid item xs={12}>
            <SectionBox>
              <Typography variant="h4" sx={{ mb: 2 }}>
               Contacts
              </Typography>
              <Typography variant="h6">
                Professor: Steve Mann,{' '}
                <Link href="mailto:mann@eyetap.org" underline="hover">
                  mann@eyetap.org
                </Link>
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Head TA: Mete Isiksalan,{' '}
                <Link href="mailto:mete.isiksalan@mail.utoronto.ca" underline="hover">
                mete.isiksalan@mail.utoronto.ca
                </Link>
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                TA: Saleh Mirtaba,{' '}
                <Link href="s.mirzatabatabaei@mail.utoronto.ca" underline="hover">
                s.mirzatabatabaei@mail.utoronto.ca
                </Link>
              </Typography>
            </SectionBox>
          </Grid>

          {/* Course Philosophy */}
          <Grid item xs={12}>
            <SectionBox>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Course Philosophy and Ethos
              </Typography>
              <Typography variant="h6">
                This course emphasizes the trinity of technology, humanity, and earth—technology in service of both humanity and the environment. It is built on the foundation of "Mersivity," which posits that if a technology is immersive, it should also be exmersive—meaning that if technology can "merse" you, you can also "merse" it. Learn more about this philosophy at <a href="https://mersivity.com" target="_blank" rel="noopener noreferrer">mersivity.com</a>.
              </Typography>
            </SectionBox>
          </Grid>

          {/* Grading Section */}
          <Grid item xs={12}>
          <SectionBox>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Grading
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="grading table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Asssements</StyledTableCell>
                      <StyledTableCell align="right">Weight</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Reading review</TableCell>
                      <TableCell align="right">10%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assignments or hands-on research</TableCell>
                      <TableCell align="right">30%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Final project</TableCell>
                      <TableCell align="right">40%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Quiz</TableCell>
                      <TableCell align="right">10%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Participation</TableCell>
                      <TableCell align="right">10%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SectionBox>
          </Grid>

          {/* Readings Section */}
          <Grid item xs={12}>
            <SectionBox>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Readings
              </Typography>
              <Typography variant="h6">
                <strong>EVERYONE READ, onboarding reading:</strong>
                <br />- eXtended meta-uni-omni-Verse (XV): Introduction, Taxonomy, and State-of-the-Art
                <br />- Proceedings of the 23rd annual Water-Human-Computer-Interface (WaterHCI 2021): Crossing the Borders of Computation, Clothes, Skin, and Surface
                <br />- Crossing the Border of Humanity: Cyborgs in Ethics, Law, and Art
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                <strong>Project specific reading:</strong>
                <br />- Wearable AI
                <br />- Humanistic Computing (Humanistic Intelligence)
                <br />- Society of Intelligent Veillance
              </Typography>
            </SectionBox>
          </Grid>

              </Grid>
            </Grid>
           
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: '#2196f3', color: '#fff', p: 2, textAlign: 'center' }}>
        <Typography variant="body1">Build V1</Typography>
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            maxWidth: '90vw',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {currentImage.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            component="img"
            src={`${BASE_URL}/${currentImage.path}`}
            alt={currentImage.title}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}
